<template>
    <v-card flat class="pa-3 mt-2">
        <v-card-title>Pacientes</v-card-title>
        <v-form class="multi-col-validation">
            <v-card-text class="pt-5">
                <v-row>

                    <v-col cols="12" md="12">

                        <v-data-table :headers="headers" :items="pacientesMudar" :search="search" sort-by="nome"
                            :footer-props="{
                                showFirstLastPage: true,
                                'items-per-page-text': 'Dados por página'
                            }">

                            <template v-slot:top>
                                <v-dialog v-model="dialog" persistent max-width="600px"
                                    transition="dialog-bottom-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" dark v-bind="attrs" v-on="on"
                                            style="margin-bottom: 20px;">
                                            Cadastro
                                        </v-btn>

                                        <v-card-title>
                                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar"
                                                single-line hide-details>
                                            </v-text-field>
                                        </v-card-title>
                                    </template>
                                    <v-card>
                                        <v-toolbar>
                                            <v-btn icon @click="fecharModal()">
                                                <v-icon>{{ icons.mdiClose }}</v-icon>
                                            </v-btn>
                                            <v-toolbar-title>Cadastro do paciente</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-btn dark text @click="alterarPaciente()" class="primary">
                                                <v-icon>{{ icons.mdiContentSave }}</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                        <v-list three-line subheader>
                                            <v-subheader>Dados obrigatórios</v-subheader>
                                            <v-card-text ref="form">
                                                <v-form class="multi-col-validation mt-6">
                                                    <v-row>

                                                        <v-col md="4" cols="12">
                                                            <v-text-field v-model="paciente.paciente" label="Paciente *"
                                                                dense outlined :error-messages="errorMessages"
                                                                ref="paciente">
                                                            </v-text-field>
                                                        </v-col>

                                                        <v-col md="4" cols="12">
                                                            <v-text-field v-model="paciente.cpf" label="CPF *"
                                                                v-mask="maskCpf" dense outlined
                                                                :error-messages="errorMessages" ref="cpf">
                                                            </v-text-field>
                                                        </v-col>

                                                        <v-col md="4" cols="12">
                                                            <v-text-field v-model="paciente.telefone" label="Telefone *"
                                                                dense outlined v-mask="'(##) #####-####'"
                                                                :error-messages="errorMessages" ref="paciente">
                                                            </v-text-field>
                                                        </v-col>

                                                        <v-col md="4" cols="12">
                                                            <v-text-field v-model="paciente.email" label="E-mail" dense
                                                                outlined :error-messages="errorMessages" ref="email">
                                                            </v-text-field>
                                                        </v-col>


                                                        <v-col md="4" cols="12">
                                                            <v-text-field v-model="paciente.cep" label="CEP" dense
                                                                v-mask="maskCep" outlined @blur="getCep()"
                                                                :error-messages="errorMessages">
                                                            </v-text-field>
                                                        </v-col>

                                                        <v-col md="4" cols="12">
                                                            <v-text-field v-model="paciente.estado" label="Estado" dense
                                                                outlined :error-messages="errorMessages">
                                                            </v-text-field>
                                                        </v-col>

                                                        <v-col md="4" cols="12">
                                                            <v-text-field v-model="paciente.cidade" label="Cidade" dense
                                                                outlined :error-messages="errorMessages">
                                                            </v-text-field>
                                                        </v-col>

                                                        <v-col md="4" cols="12">
                                                            <v-text-field v-model="paciente.bairro" label="Bairro" dense
                                                                outlined :error-messages="errorMessages">
                                                            </v-text-field>
                                                        </v-col>


                                                    </v-row>
                                                </v-form>
                                            </v-card-text>

                                        </v-list>
                                    </v-card>
                                </v-dialog>
                                <v-dialog v-model="dialogDelete" max-width="500px">
                                    <v-card class="text-center">
                                        <v-card-text class="text-h5 text-center">Deseja deletar o paciente ?
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="warning" outlined @click="closeDelete">Cancelar</v-btn>
                                            <v-btn color="success" outlined @click="deleteItemConfirm">OK</v-btn>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon small class="mr-2" @click="visualizar(item)">
                                    {{ icons.mdiEye }}
                                </v-icon>
                                <v-icon small class="mr-2" @click="editItem(item)">
                                    {{ icons.mdiPencil }}
                                </v-icon>
                                <v-icon small @click="deleteItem(item)">
                                    {{ icons.mdiDelete }}
                                </v-icon>
                            </template>
                            <template v-slot:no-data>
                                Nenhum paciente encontrado
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-form>

        <v-snackbar v-model="snackbar" :color="color" class="text-center">
            {{ text }}
        </v-snackbar>
    </v-card>
</template>

<script>
import Vue from "vue";
import { ref } from '@vue/composition-api'
import { mdiClose, mdiContentSave, mdiDelete, mdiPencil, mdiEye } from '@mdi/js'

import { mask } from 'vue-the-mask'

export default {

    directives: { mask }
    ,

    setup() {
        const isNewPasswordVisible = ref(false)

        return {
            pacientesA: [],
            isNewPasswordVisible,
            search: "",
            paciente: { id: null, paciente: "", cpf: "", cep: "", telefone: "", email: "", estado: "", cidade: "", bairro: "", idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token") },
            tipos: [{ id: 1, name: "Administrador" }, { id: 2, name: "Comum" }, { id: 3, name: "Profissional" }],
            dialog: false,
            dialogDelete: false,
            newPassword: '',
            pacientesMudar: [],
            maskCpf: "###.###.###-##",
            maskCep: "#####-###",

            response: null,
            baseUrl: 'https://viacep.com.br/ws/',

            notifications: false,
            sound: true,
            widgets: false,
            icons: {
                mdiClose,
                mdiDelete,
                mdiContentSave,
                mdiPencil,
                mdiEye
            },
            formHasErrors: false,
            errorMessages: '',
            snackbar: false,
            text: '',
            color: 'success',

            headers: [
                {
                    text: 'Paciente',
                    align: 'start',
                    value: 'paciente',
                    sortable: false
                },
                { text: 'CPF', value: 'cpf', sortable: false },
                { text: 'cep', value: 'cep', sortable: false },
                { text: 'Telefone', value: 'telefone', sortable: false },
                { text: 'E-mail', value: 'email', sortable: false },
                { text: 'Estado', value: 'estado', sortable: false },
                { text: 'Cidade', value: 'cidade', sortable: false },
                { text: 'Bairro', value: 'bairro', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            editedIndex: -1,
            defaultPaciente: { id: null, paciente: "", cpf: "", cep: "", telefone: "", email: "", estado: "", cidade: "", bairro: "", idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token") },
        }
    },

    methods: {

        pegarDados: function () {
            this.$http.get("pacientes?idEmpresa=" + localStorage.getItem("idEmpresa") + "&token=" + localStorage.getItem("token"))
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        var newPacientes = []
                        for (var i = 0; i < res.data.dados.length; i++) {
                            res.data.dados[i].token = localStorage.getItem("token")
                            newPacientes.push(res.data.dados[i])
                        }

                        this.pacientesMudar = newPacientes

                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        fecharModal: function () {
            this.formHasErrors = false
            this.paciente = { id: null, paciente: "", cpf: "", cep: "", telefone: "", email: "", estado: "", cidade: "", bairro: "", idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token") };
            this.dialog = !this.dialog
        },

        alterarPaciente: function () {

            if (this.paciente.paciente != "" && this.paciente.cpf != "" && this.paciente.telefone != "") {
                if (!this.validarCPF(this.paciente.cpf)) {
                    this.text = "CPF inválido"
                    this.color = "error"
                    this.snackbar = true

                    return
                }

                if (this.paciente.id != null) {
                    this.$http.post("editarPaciente", this.paciente)
                        .then((res) => {
                            if (res.data.status != undefined && res.data.status == 200) {
                                if (this.paciente.id == null) {
                                    this.pacientesMudar.push(res.data.dados)
                                    this.text = "Paciente cadastrado"
                                    this.color = "success"
                                    this.snackbar = true
                                    this.dialog = !this.dialog
                                } else {
                                    this.text = "Paciente atualizado"
                                    this.color = "success"
                                    this.snackbar = true
                                    this.dialog = !this.dialog
                                }

                                this.pegarDados();

                            } else if (res.data.status != undefined && res.data.status == 500) {
                                this.text = res.data.msg
                                this.color = "error"
                                this.snackbar = true
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    this.$http.post("cadastroPaciente", this.paciente)
                        .then((res) => {
                            if (res.data.status != undefined && res.data.status == 200) {
                                if (this.paciente.id == null) {
                                    this.pacientesMudar.push(res.data.dados)
                                    this.text = "Paciente cadastrado"
                                    this.color = "success"
                                    this.snackbar = true
                                    this.dialog = !this.dialog
                                } else {
                                    Object.assign(this.pacientesMudar[this.editedIndex], this.paciente)

                                    this.text = "Paciente atualizado"
                                    this.color = "success"
                                    this.snackbar = true
                                    this.dialog = !this.dialog
                                }

                                this.pegarDados();

                            } else if (res.data.status != undefined && res.data.status == 500) {
                                this.text = res.data.msg
                                this.color = "error"
                                this.snackbar = true
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }

            } else {
                this.text = "Favor preencher todos campos obrigatórios"
                this.color = "warning"
                this.snackbar = true
            }
        },

        editItem(item) {
            this.editedIndex = this.pacientesMudar.indexOf(item)
            this.paciente = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.pacientesMudar.indexOf(item)
            this.paciente = Object.assign({}, item)
            this.dialogDelete = true

        },

        deleteItemConfirm() {
            this.pacientesMudar.splice(this.editedIndex, 1)
            this.closeDelete()

            this.$http.post("deletarPaciente", {
                id: this.paciente.id, token: localStorage.getItem("token")
            })
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.text = "Paciente deletado"
                        this.color = "success"
                        this.snackbar = true

                        this.pegarDados();

                    } else if (res.data.status != undefined && res.data.status == 500) {
                        this.text = res.data.msg
                        this.color = "error"
                        this.snackbar = true
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.paciente = Object.assign({}, this.defaultPaciente)
                this.editedIndex = -1
            })
        },

        closeDelete() {

            this.dialogDelete = false
            this.$nextTick(() => {
                this.paciente = Object.assign({}, this.defaultPaciente)
                this.editedIndex = -1


            })
        },

        visualizar(item) {
            this.$router.push('paciente/'+item.id)
        },

        getCep() {
            const url = `${this.baseUrl}${this.paciente.cep}/json/`
            this.$http.get(url).then(resp => {
                const data = resp.data
                if (!data.erro) {
                    this.response = data
                    this.paciente.bairro = this.response.bairro
                    this.paciente.cidade = this.response.localidade
                    this.paciente.estado = this.response.uf

                    this.$forceUpdate();
                    console.log(this.response)
                } else {
                    alert('Cep não encontrado')
                }
            }).catch(error => {
                console.error(error)
            })
        },

        validarCPF(cpf) {
            cpf = cpf.replace(/[^\d]+/g, '');
            if (cpf == '') return false;
            // Elimina CPFs invalidos conhecidos	
            if (cpf.length != 11 ||
                cpf == "00000000000" ||
                cpf == "11111111111" ||
                cpf == "22222222222" ||
                cpf == "33333333333" ||
                cpf == "44444444444" ||
                cpf == "55555555555" ||
                cpf == "66666666666" ||
                cpf == "77777777777" ||
                cpf == "88888888888" ||
                cpf == "99999999999")
                return false;
            // Valida 1o digito	
            var add = 0;
            for (var i = 0; i < 9; i++)
                add += parseInt(cpf.charAt(i)) * (10 - i);
            var rev = 11 - (add % 11);
            if (rev == 10 || rev == 11)
                rev = 0;
            if (rev != parseInt(cpf.charAt(9)))
                return false;
            // Valida 2o digito	
            add = 0;
            for (var i = 0; i < 10; i++)
                add += parseInt(cpf.charAt(i)) * (11 - i);
            rev = 11 - (add % 11);
            if (rev == 10 || rev == 11)
                rev = 0;
            if (rev != parseInt(cpf.charAt(10)))
                return false;
            return true;
        }

    },

    created() {

    },

    beforeMount() {
        var logado = localStorage.getItem("login")
        if (logado != undefined && logado == "true") {
            this.pegarDados()
        } else {
            this.$router.push('login')
        }
    },

    watch: {
        name() {
            this.errorMessages = ''
        },

        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
}
</script>
